const initialState = {
  user: null,
  error: null,
  isLoading: false,
};

const user = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_USER':
      return {
        ...state,
        user: payload,
      };
    case 'SET_USER_LOADING':
      return {
        ...state,
        isLoading: payload,
      };
    case 'SET_USERS_ERROR':
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
};

export default user;
