import React from 'react';

export const Loading = () => {
  return (
    <div className="loading">
      <div className="wrapper">
        <p>Loading...</p>
      </div>
    </div>
  );
};
