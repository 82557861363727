import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { reportsActions } from 'store/actions';
import { useActions } from 'hooks';
import { Icon } from 'components';

export const ReportsItem = ({
  brand,
  category,
  checked,
  description,
  duplicate,
  id,
  image,
  link,
  name,
  productId,
  productName,
  userId,
  userName,
}) => {
  const { setReportChecked } = useActions(reportsActions);
  const [isOpen, setIsOpen] = useState(false);

  const errors = [
    { checked: name, value: 'name' },
    { checked: brand, value: 'brand' },
    { checked: description, value: 'description' },
    { checked: image, value: 'image' },
    { checked: category, value: 'category' },
    { checked: duplicate, value: 'duplicate' },
  ];

  const handleClickShowMore = async () => {
    try {
      setIsOpen(!isOpen);

      if (!checked) {
        await setReportChecked(id);
      }
    } catch (error) {
      toast.error(error.message, {
        autoClose: false,
      });
    }
  };

  return (
    <div className="report collapse">
      <div className="collapse__header report__header">
        <div className="report__badge">
          <Icon
            icon={checked ? 'exclamation-close' : 'exclamation-open'}
            width="16"
            height="16"
            className={checked ? 'report__icon report__icon_close' : 'report__icon report__icon_open'}
          />
        </div>
        <div className="report__info">
          <Link to={`user/${userId}`} className="report__link">
            {userName}
          </Link>{' '}
          reported a bug on{' '}
          <Link to={`/product/${productId}`} className="report__link">
            {productName}
          </Link>
          .{' '}
          <button type="button" className="report__btn" onClick={handleClickShowMore}>
            Show more
          </button>
        </div>
      </div>
      <div className={isOpen ? 'collapse__content collapse__content_open' : 'collapse__content'}>
        <p>Something wrong with:</p>
        {errors && (
          <ul className="report__list">
            {errors.map((error, index) => {
              return (
                error.checked && (
                  <li key={index} className="report__item">
                    {error.value}
                  </li>
                )
              );
            })}
          </ul>
        )}
        <p>
          Link to right info:{' '}
          <a className="report__link" href={link} target="_blank" rel="noreferrer">
            {link}
          </a>
        </p>
      </div>
    </div>
  );
};
