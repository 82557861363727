import React, { forwardRef } from 'react';
import { TextareaAutosize } from '@mui/material';

export const TextField = forwardRef(
  ({ label, className, value, helperText, error = false, onChange, multiline = false, floating, ...attrs }, ref) => {
    const classes = ['field-text'];

    if (className) {
      classes.push(className);
    }

    if (error) {
      classes.push('field-text_error');
    }

    if (floating) {
      classes.push('field-text_floating');
    }

    return (
      <label className={classes.join(' ')}>
        {floating ? (
          <span className="field-text__input-wrap">
            {multiline ? (
              <>
                <TextareaAutosize
                  value={value}
                  onChange={onChange}
                  className="field-text__input"
                  placeholder={label}
                  ref={ref}
                  {...attrs}
                />
                <span className="field-text__name">{label}</span>
              </>
            ) : (
              <>
                <input
                  ref={ref}
                  type="text"
                  value={value}
                  onChange={onChange}
                  className="field-text__input"
                  placeholder={label}
                  {...attrs}
                />
                <span className="field-text__name">{label}</span>
              </>
            )}
            {helperText && <span className="field-text__help-text">{helperText}</span>}
          </span>
        ) : (
          <>
            <span className="field-text__name">{label}</span>
            <span className="field-text__input-wrap">
              {multiline ? (
                <TextareaAutosize
                  value={value}
                  onChange={onChange}
                  className="field-text__input"
                  ref={ref}
                  {...attrs}
                />
              ) : (
                <input
                  ref={ref}
                  type="text"
                  value={value}
                  onChange={onChange}
                  className="field-text__input"
                  {...attrs}
                />
              )}
              {helperText && <span className="field-text__help-text">{helperText}</span>}
            </span>
          </>
        )}
      </label>
    );
  },
);
