import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { routes } from 'router';
import { Loader } from 'components';

export const AppRouter = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {routes.map(({ component, path, exact }) => (
          <Route key={path} component={component} path={path} exact={exact} />
        ))}
      </Switch>
    </Suspense>
  );
};
