const initialState = {
  news: null,
  totalCount: null,
  error: null,
  isLoading: false,
};

const news = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_NEWS':
      return {
        ...state,
        news: payload.result,
        totalCount: payload.count,
      };
    case 'SET_NEWS_LOADING':
      return {
        ...state,
        isLoading: payload,
      };
    case 'SET_NEWS_ERROR':
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
};

export default news;
