import React from 'react';
import { MainNav } from 'components';

export const MobileMenu = ({ className, isOpenMobileMenu, onClickItemMenu }) => {
  const classes = ['mobile-menu'];

  if (className) {
    classes.push(className);
  }

  if (isOpenMobileMenu) {
    classes.push('mobile-menu_open');
  }

  return (
    <aside className={classes.join(' ')}>
      <div className="wrapper mobile-menu__wrapper">
        <MainNav className="mobile-menu__main-nav" onClickItemMenu={onClickItemMenu} />
      </div>
    </aside>
  );
};

export default MobileMenu;
