import { Checkbox, FormControlLabel } from '@mui/material';
import { useFormContext } from 'react-hook-form';

export const FormCheckbox = ({ name, label }) => {
  const { watch, setValue } = useFormContext();

  const value = watch(name);

  const handleChange = ({ target: { checked } }) => {
    setValue(name, checked);
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={Boolean(value)}
          onChange={handleChange}
          name={name}
        />
      }
      label={label}
    />
  );
};
