import React, { useState } from 'react';
import axios from 'axios';
import { NewsItem, Error, Loading } from 'components';

export const NewsList = ({ news, isLoading, error }) => {
  const [movingItemId, setMovingItemId] = useState(null);

  const handleDragStart = (event, id) => {
    setMovingItemId(id);
  };

  const handleDragEnd = (event) => {
    event.target.style.background = 'white';
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.target.style.background = 'red';
  };

  const handleDrop = (event, id) => {
    event.preventDefault();
    event.target.style.background = 'white';

    if (id !== movingItemId) {
      axios
        .put(`/api/v1/news/changeOrder?movingItemId=${movingItemId}&movedToItemId=${id}`)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <div className="news-list">
      <div className="wrapper">
        {news?.length === 0 ? (
          <p>Not found</p>
        ) : (
          news?.map((article) => (
            <NewsItem
              key={article.id}
              {...article}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            />
          ))
        )}
      </div>
    </div>
  );
};
