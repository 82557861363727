import React from 'react';

export const Burger = ({ className, onClickBurger, isOpenMobileMenu }) => {
  const classes = ['burger'];

  if (className) {
    classes.push(className);
  }

  if (isOpenMobileMenu) {
    classes.push('burger_open');
  }

  return (
    <button type="button" className={classes.join(' ')} onClick={onClickBurger}>
      <span className="burger__inner">
        <span className="burger__line" />
      </span>
    </button>
  );
};
