const initialState = {
  article: {
    title: '',
    body: '',
    picture: '',
    author: '',
    thumbnail: '',
  },
  error: {
    article: null,
    articleUploadImage: null,
    articleUploadPreview: null,
  },
  isLoading: {
    article: false,
    articleUploadImage: false,
    articleUploadPreview: false,
  },
};

const article = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_ARTICLE':
      return {
        ...state,
        article: payload,
      };
    case 'SET_ARTICLE_IMAGE':
      return {
        ...state,
        article: {
          ...state.article,
          picture: payload,
        },
      };
    case 'SET_ARTICLE_PREVIEW':
      return {
        ...state,
        article: {
          ...state.article,
          thumbnail: payload,
        },
      };
    case 'SET_ARTICLE_LOADING':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          article: payload,
        },
      };
    case 'SET_ARTICLE_UPLOAD_IMAGE_LOADING':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          articleUploadImage: payload,
        },
      };
    case 'SET_ARTICLE_UPLOAD_PREVIEW_LOADING':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          articleUploadPreview: payload,
        },
      };
    case 'SET_ARTICLE_UPLOAD_IMAGE_ERROR':
      return {
        ...state,
        error: {
          ...state.error,
          articleUploadImage: payload,
        },
      };
    case 'SET_ARTICLE_UPLOAD_PREVIEW_ERROR':
      return {
        ...state,
        error: {
          ...state.error,
          articleUploadPreview: payload,
        },
      };
    case 'SET_ARTICLE_ERROR':
      return {
        ...state,
        error: {
          ...state.error,
          article: payload,
        },
      };
    default:
      return state;
  }
};

export default article;
