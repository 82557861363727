const fetchMetadata =
  () =>
  async (dispatch, _, { apiV1 }) => {
    dispatch(setMetadataLoading(true));
    await apiV1
      .get('/seo/metadata')
      .then((response) => {
        dispatch(setMetadata(response.data));
      })
      .catch((error) => {
        dispatch(setMetadataError(error));
      })
      .finally(() => {
        dispatch(setMetadataLoading(false));
      });
  };

const updateMetadata =
  (metadata) =>
  async (dispatch, _, { apiV1 }) => {
    await apiV1.put('/seo/metadata', metadata);
  };

const setMetadata = (metadata) => ({
  type: 'SET_METADATA',
  payload: metadata,
});

const setMetadataLoading = (isLoading) => ({
  type: 'SET_METADATA_LOADING',
  payload: isLoading,
});

const setMetadataError = (error) => ({
  type: 'SET_METADATA_ERROR',
  payload: error,
});

export const seoActions = {
  fetchMetadata,
  updateMetadata,
  setMetadata,
  setMetadataLoading,
  setMetadataError,
};
