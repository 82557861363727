import { Done as DoneIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  Accordion, accordionClasses,
  AccordionDetails,
  AccordionSummary,
  Box, Chip,
  Typography,
} from '@mui/material';
import { useState } from 'react';

const styles = {
  root: theme => ({

    [`&.${accordionClasses.expanded}`]: {
      boxShadow: `0 0 5px 0 ${theme.palette.primary.light}`,
    },
  }),
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  summary: {
    '& > div:first-child': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: 1,
      paddingRight: 1,
      minWidth: 0,
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    ml: 'auto',
    gap: 1,
  },
  details: theme => ({
    bgcolor: theme.palette.grey[50],
  }),
};

export const IngredientItem = ({ ingredient, headerSlot = null, children }) => {
  const { name, triaged, masterIngredient } = ingredient;

  const [isExpanded, setIsExpanded] = useState(false);
  const triagedColor = triaged ? 'primary' : 'disabled';
  const isAlias = Boolean(masterIngredient);

  const handleToggle = () => {
    setIsExpanded(value => !value);
  };

  return (
    <Accordion expanded={isExpanded} slotProps={{ transition: { unmountOnExit: true } }} onChange={handleToggle}
               sx={styles.root}>
      <AccordionSummary aria-controls={name}
                        id={name} sx={styles.summary} expandIcon={<ExpandMoreIcon />}>
          <DoneIcon color={triagedColor} />
        <Typography sx={styles.title}>{name}</Typography>
        {isAlias && <Chip label="Alias" size="small" variant="outlined" color="primary" title={masterIngredient.name} />}
        <Box sx={styles.actions}>{headerSlot}</Box>
      </AccordionSummary>

      <AccordionDetails sx={styles.details}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};
