const initialState = {
  reports: null,
  totalCount: null,
  error: null,
  isLoading: false,
};

const reports = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_REPORTS':
      return {
        ...state,
        reports: payload.result,
        totalCount: payload.count,
      };
    case 'SET_REPORTS_LOADING':
      return {
        ...state,
        isLoading: payload,
      };
    case 'SET_REPORTS_ERROR':
      return {
        ...state,
        error: payload,
      };
    case 'SET_REPORT_CHECKED_SUCCESS':
      return {
        ...state,
        reports: state.reports.map((report) => {
          if (report.id === payload) {
            return {
              ...report,
              checked: true,
            };
          }
          return report;
        }),
      };
    default:
      return state;
  }
};

export default reports;
