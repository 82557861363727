import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';

export const FormTextField = ({ name, ...rest }) => {
  const { register, getFieldState, formState } = useFormContext();

  const { error } = getFieldState(name, formState);

  return (
    <TextField
      {...register(name)}
      fullWidth
      margin="normal"
      InputLabelProps={{ shrink: true }}
      error={!!error}
      helperText={error?.message}
      {...rest}
    />
  );
};
