import { forwardRef } from 'react';

import { Icon } from 'components';

export const SelectField = forwardRef(
  ({ label, className, options, helperText, error = false, disabled, onChange, value, ...attrs }, ref) => {
    const classes = ['field-select'];

    if (className) {
      classes.push(className);
    }

    if (error) {
      classes.push('field-select_error');
    }

    return (
      <label className={classes.join(' ')}>
        {label && <span className="field-select__label">{label}</span>}
        <span className="field-select__select-wrap">
          <select
            className="field-select__select"
            disabled={disabled}
            onChange={onChange}
            ref={ref}
            defaultValue={value ? value : ''}
            {...attrs}
          >
            <option value="" disabled>
              Choose {label.toLowerCase()}
            </option>
            {options?.map((option, index) => {
              return (
                <option key={index} value={option} className="field-select__option">
                  {option}
                </option>
              );
            })}
          </select>
          <Icon width="18" height="18" icon="arrow-down" className="field-select__icon" aria-label="" />
          {helperText && <span className="field-select__help-text">{helperText}</span>}
        </span>
      </label>
    );
  },
);
