const initialState = {
  brands: null,
  isLoading: false,
  error: null,
};

const brands = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_BRANDS':
      return {
        ...state,
        brands: payload,
      };
    case 'SET_BRANDS_LOADING':
      return {
        ...state,
        isLoading: payload,
      };
    case 'SET_BRANDS_ERROR':
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
};

export default brands;
