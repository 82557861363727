const fetchProducts =
  ({ pageNumber = 1, pageSize = 20 }) =>
  async (dispatch, _, { apiV1 }) => {
    dispatch(setProductsLoading(true));
    await apiV1
      .get('/products', {
        params: {
          pageNumber,
          pageSize,
        },
      })
      .then((response) => {
        dispatch(setProducts(response.data));
      })
      .catch((error) => {
        dispatch(setProductsError(error));
      })
      .finally(() => {
        dispatch(setProductsLoading(false));
      });
  };

const fetchSearchProducts =
  ({ pageNumber = 1, pageSize = 20, text }) =>
  async (dispatch, _, { apiV1 }) => {
    dispatch(setProductsLoading(true));
    await apiV1
      .get('/product/search/paged', {
        params: {
          pageNumber,
          pageSize,
          text,
        },
      })
      .then((response) => {
        dispatch(setProducts(response.data));
      })
      .catch((error) => {
        dispatch(setProductsError(error));
      })
      .finally(() => {
        dispatch(setProductsLoading(false));
      });
  };

const setProducts = (products) => ({
  type: 'SET_PRODUCTS',
  payload: products,
});

const setProductsLoading = (isLoading) => ({
  type: 'SET_PRODUCTS_LOADING',
  payload: isLoading,
});

const setProductsError = (error) => ({
  type: 'SET_PRODUCTS_ERROR',
  payload: error,
});

export const productsActions = {
  fetchProducts,
  fetchSearchProducts,
  setProducts,
  setProductsLoading,
  setProductsError,
};
