import React from 'react';
import { Link } from 'react-router-dom';
import headerLogo from 'img/logo/logo_skintory.svg';
import { MainNav, Burger } from 'components';

export const Header = ({ onClickBurger, isOpenMobileMenu, onClickLogo }) => {
  return (
    <header className="page-header">
      <div className="wrapper page-header__wrapper">
        <Burger onClickBurger={onClickBurger} isOpenMobileMenu={isOpenMobileMenu} />
        <div className="logo page-header__logo">
          <Link to="/" className="logo__link" onClick={onClickLogo}>
            <img width="140" height="51" className="logo__img" src={headerLogo} alt="Skintory" loading="lazy" />
          </Link>
        </div>
        <MainNav className="page-header__main-nav" />
      </div>
    </header>
  );
};
