import React from 'react';
import { CatalogItem, Error, Loading } from 'components';

export const Catalog = ({ products, isLoading, error }) => {
  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <div className="catalog-list">
      <div className="wrapper catalog-list__wrapper">
        {products?.length === 0 ? (
          <p>Not found</p>
        ) : (
          products?.map((product) => <CatalogItem key={product.id} {...product} />)
        )}
      </div>
    </div>
  );
};
