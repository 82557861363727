const initialState = {
  brand: null,
  isLoading: false,
  error: null,
};

const brand = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_BRAND':
      return {
        ...state,
        brand: payload,
      };
    case 'SET_BRAND_LOADING':
      return {
        ...state,
        isLoading: payload,
      };
    case 'SET_BRAND_ERROR':
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
};

export default brand;
