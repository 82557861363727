import React from 'react';
import { Link } from 'react-router-dom';

const locale = 'ru-RU';
const dateOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

export const NewsItem = ({ id, title, picture, publishedAt, onDragStart, onDragEnd, onDragOver, onDrop }) => {
  const date = new Date(Date.parse(publishedAt));
  const handleDragStart = (event) => onDragStart(event, id);
  const handleDrop = (event) => onDrop(event, id);

  return (
    <article
      className="news"
      draggable={true}
      onDragStart={handleDragStart}
      onDragLeave={onDragEnd}
      onDragEnd={onDragEnd}
      onDragOver={onDragOver}
      onDrop={handleDrop}
    >
      <Link to={`/article/${id}`} className="news__link">
        <picture className="news__picture">
          <img src={picture} alt={title} className="news__img" width="180" height="120" loading="lazy" />
        </picture>
      </Link>
      <div className="news__info">
        <h2 className="news__title">
          <Link to={`/article/${id}`} className="news__link">
            {title}
          </Link>
        </h2>
        <time dateTime={publishedAt} className="news__date">
          {date.toLocaleString(locale, dateOptions)}
        </time>
      </div>
    </article>
  );
};
