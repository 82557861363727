const fetchArticle =
  (id) =>
  async (dispatch, _, { apiV1 }) => {
    dispatch(setArticleLoading(true));
    await apiV1
      .get(`/news/${id}`)
      .then((response) => {
        dispatch(setArticle(response.data));
      })
      .catch((error) => {
        dispatch(setArticleError(error));
      })
      .finally(() => {
        dispatch(setArticleLoading(false));
      });
  };

const uploadImageArticle =
  (formData) =>
  async (dispatch, _, { apiV1 }) => {
    dispatch(setArticleUploadImageLoading(true));
    await apiV1
      .post('/news/pictureUpload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        dispatch(setArticleImage(response.data));
      })
      .catch((error) => {
        dispatch(setArticleUploadImageError(error));
      })
      .finally(() => {
        dispatch(setArticleUploadImageLoading(false));
      });
  };

const uploadPreviewArticle =
  (formData) =>
  async (dispatch, _, { apiV1 }) => {
    dispatch(setArticleUploadPreviewLoading(true));
    await apiV1
      .post('/news/thumbnailUpload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        dispatch(setArticlePreview(response.data));
      })
      .catch((error) => {
        dispatch(setArticleUploadPreviewError(error));
      })
      .finally(() => {
        dispatch(setArticleUploadPreviewLoading(false));
      });
  };

const deleteArticle =
  (id) =>
  async (dispatch, _, { apiV1 }) => {
    await apiV1.delete(`/news/${id}`);
  };

const addArticle =
  (article) =>
  async (dispatch, _, { apiV1 }) => {
    await apiV1.post('/news', article);
  };

const updateArticle =
  (article) =>
  async (dispatch, _, { apiV1 }) => {
    return await apiV1.put('/news', article);
  };

const setArticle = (article) => ({
  type: 'SET_ARTICLE',
  payload: article,
});

const setArticleImage = (imageLink) => ({
  type: 'SET_ARTICLE_IMAGE',
  payload: imageLink,
});

const setArticlePreview = (previewLink) => ({
  type: 'SET_ARTICLE_PREVIEW',
  payload: previewLink,
});

const setArticleLoading = (isLoading) => ({
  type: 'SET_ARTICLE_LOADING',
  payload: isLoading,
});

const setArticleError = (error) => ({
  type: 'SET_ARTICLE_ERROR',
  payload: error,
});

const setArticleUploadImageLoading = (isLoading) => ({
  type: 'SET_ARTICLE_UPLOAD_IMAGE_LOADING',
  payload: isLoading,
});

const setArticleUploadImageError = (error) => ({
  type: 'SET_ARTICLE_UPLOAD_IMAGE_ERROR',
  payload: error,
});

const setArticleUploadPreviewLoading = (isLoadingPreview) => ({
  type: 'SET_ARTICLE_UPLOAD_PREVIEW_LOADING',
  payload: isLoadingPreview,
});

const setArticleUploadPreviewError = (errorPreview) => ({
  type: 'SET_ARTICLE_UPLOAD_PREVIEW_ERROR',
  payload: errorPreview,
});

export const articleActions = {
  fetchArticle,
  uploadImageArticle,
  uploadPreviewArticle,
  deleteArticle,
  addArticle,
  updateArticle,
  setArticle,
  setArticleImage,
  setArticlePreview,
  setArticleLoading,
  setArticleError,
  setArticleUploadImageLoading,
  setArticleUploadImageError,
  setArticleUploadPreviewLoading,
  setArticleUploadPreviewError,
};
