import React from 'react';

export const Error = ({ error }) => {
  return (
    <div className="error">
      <div className="wrapper">
        <p>{error.message}</p>
      </div>
    </div>
  );
};
