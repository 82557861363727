import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from 'hooks';

import { reportsActions } from 'store/actions';

export const useReports = (pageNumber) => {
  const { fetchReports, setReportsError } = useActions(reportsActions);

  const reports = useSelector(({ reports }) => reports.reports);
  const reportsTotalCount = useSelector(({ reports }) => reports.totalCount);
  const isLoadingReports = useSelector(({ reports }) => reports.isLoading);
  const errorReports = useSelector(({ reports }) => reports.error);

  useEffect(() => {
    fetchReports(pageNumber);

    return () => {
      setReportsError(null);
    };
  }, [fetchReports, pageNumber, setReportsError]);

  return {
    reports,
    reportsTotalCount,
    isLoadingReports,
    errorReports,
  };
};
