const initialState = {
  categories: null,
  isLoading: false,
  error: null,
};

const categories = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_CATEGORIES':
      return {
        ...state,
        categories: payload,
      };
    case 'SET_CATEGORIES_LOADING':
      return {
        ...state,
        isLoading: payload,
      };
    case 'SET_CATEGORIES_ERROR':
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
};

export default categories;
