import article from './article';
import brand from './brand';
import brands from './brands';
import categories from './categories';
import ingredients from './ingredients';
import metadata from './metadata';
import news from './news';
import product from './product';
import products from './products';
import reports from './reports';
import requests from './requests';
import user from './user';
import users from './users';

export const oldReducers = {
  products,
  news,
  article,
  requests,
  reports,
  categories,
  product,
  brands,
  brand,
  users,
  user,
  metadata,
  ingredients,
}
