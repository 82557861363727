import React from 'react';
import { Link } from 'react-router-dom';

import defaultUserLogo from 'img/user/logo_user.svg';

export const UsersItem = ({ picture, userName, country, status }) => {
  const userPicture = picture ? picture : defaultUserLogo;

  return (
    <>
      <article className="user">
        <div className="user__avatar-wrap">
          <Link to={`user/${userName}`} className="user__link">
            <img src={userPicture} alt={userName} className="user__avatar" width="80" height="80" loading="lazy" />
          </Link>
        </div>
        <div className="user__info">
          <h2 className="user__name">
            <Link to={`user/${userName}`} className="user__link">
              {userName}
            </Link>
          </h2>
          {country && <p className="user__country">{country}</p>}
          {status && <p className="user__status">{status}</p>}
        </div>
      </article>
    </>
  );
};
