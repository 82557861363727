import { Dialog } from '@mui/material';
import { toast } from 'react-toastify';

import { Button, CustomDialog } from 'components';

import { useDeleteIngredientMutation } from 'entities/ingredient';

export const DeleteIngredientDialog = (props) => {
  const { ingredient, open, onClose } = props;

  const [deleteIngredient] = useDeleteIngredientMutation();

  const { id, name } = ingredient;

  const handleDeleteIngredient = async (event) => {
    event.stopPropagation();
    try {
      await deleteIngredient(id).unwrap();
      toast.success(`Ingredient "${name}" deleted successfully`, {
        autoClose: false,
      });
      onClose();
    } catch (error) {
      toast.error(error.message, {
        autoClose: false,
      });
    }
  };

  const handleClose = (event) => {
    event.stopPropagation();
    onClose();
  };

  return <CustomDialog>
    <Dialog
      onClose={handleClose}
      aria-labelledby={`Delete ingredient ${name} ?`}
      open={open}
      fullWidth
    >
      <div className="dialog__header">
        <h3 className="dialog__title">{`Delete ingredient ${name} ?`}</h3>
      </div>
      <div className="dialog__body">
        <form
          action="#"
          className="form dialog__form delete-product-form"
        >
          <div className="field-actions delete-product-form__field-actions">
            <Button
              className="field-actions__btn"
              variant="contained"
              title="Cancel"
              onClick={handleClose}
            >
              Cancel
            </Button>

            <Button type="submit" className="field-actions__btn" variant="outlined" title="Ok"
                    onClick={handleDeleteIngredient}>
              Ok
            </Button>
          </div>
        </form>
      </div>
    </Dialog>
  </CustomDialog>;
};
