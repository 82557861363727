import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from 'hooks';

import { productsActions } from 'store/actions';

export const useProducts = ({ pageNumber, text }) => {
  const { fetchSearchProducts, fetchProducts, setProductsError } = useActions(productsActions);

  const products = useSelector(({ products }) => products.products);
  const productsTotalCount = useSelector(({ products }) => products.totalCount);
  const isLoadingProducts = useSelector(({ products }) => products.isLoading);
  const errorProducts = useSelector(({ products }) => products.error);

  useEffect(() => {
    text ? fetchSearchProducts({ pageNumber, text }) : fetchProducts({ pageNumber });

    return () => {
      setProductsError(null);
    };
  }, [fetchProducts, fetchSearchProducts, pageNumber, setProductsError, text]);

  return {
    products,
    productsTotalCount,
    isLoadingProducts,
    errorProducts,
  };
};
