import React from 'react';
import { usePopup } from 'hooks';
import { DeleteIngredientDialog } from './DeleteIngredientDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';

export const DeleteIngredientButton = (props) => {
  const { ingredient, children = null } = props;

  const {
    isOpen,
    openPopup,
    closePopup,
  } = usePopup();

  const handleClick = (event) => {
    event.stopPropagation();
    openPopup();
  }

  return <>
    <IconButton aria-label="delete" color="error" onClick={handleClick}>
      <DeleteIcon />
    </IconButton>

    <DeleteIngredientDialog ingredient={ingredient} open={isOpen} onClose={closePopup} />
  </>
}