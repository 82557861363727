const initialState = {
  product: {
    imageLink: '',
    name: '',
    brand: {
      name: '',
      country: '',
      producer: '',
    },
    category: {
      name: '',
      subCategories: [],
    },
    subCategory: {
      name: '',
    },
    additionalInfo: '',
    ingredients: '',
    userRequestedName: '',
    seoMetadataDescription: '',
    seoMetadataTitle: '',
    amazonUrl: '',
  },
  error: {
    product: null,
    productUploadImage: null,
  },
  isLoading: {
    product: false,
    productUploadImage: false,
  },
};

const product = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_PRODUCT':
      return {
        ...state,
        product: payload,
      };
    case 'SET_PRODUCT_IMAGE':
      return {
        ...state,
        product: {
          ...state.product,
          imageLink: payload,
        },
      };
    case 'SET_PRODUCT_LOADING':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          product: payload,
        },
      };
    case 'SET_PRODUCT_ERROR':
      return {
        ...state,
        error: {
          ...state.error,
          product: payload,
        },
      };
    case 'SET_PRODUCT_UPLOAD_IMAGE_LOADING':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          productUploadImage: payload,
        },
      };
    case 'SET_PRODUCT_UPLOAD_IMAGE_ERROR':
      return {
        ...state,
        error: {
          ...state.error,
          productUploadImage: payload,
        },
      };
    default:
      return state;
  }
};

export default product;
