import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from 'hooks';

import { brandActions } from 'store/actions';

export const useBrand = (id) => {
  const { fetchBrand, setBrandError, setBrand } = useActions(brandActions);

  const brand = useSelector(({ brand }) => brand.brand);
  const isLoadingBrand = useSelector(({ brand }) => brand.isLoading);
  const errorBrand = useSelector(({ brand }) => brand.error);

  useEffect(() => {
    fetchBrand(id);

    return () => {
      setBrandError(null);
      setBrand({
        name: '',
        country: '',
        producer: '',
      });
    };
  }, [fetchBrand, id, setBrand, setBrandError]);

  return {
    brand,
    isLoadingBrand,
    errorBrand,
  };
};
