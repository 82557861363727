const initialState = {
  requests: null,
  totalCount: null,
  error: null,
  isLoading: false,
};

const requests = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_REQUESTS':
      return {
        ...state,
        requests: payload.result,
        totalCount: payload.count,
      };
    case 'SET_REQUESTS_LOADING':
      return {
        ...state,
        isLoading: payload,
      };
    case 'SET_REQUESTS_ERROR':
      return {
        ...state,
        error: payload,
      };
    case 'SET_REQUEST_CHECKED_SUCCESS':
      return {
        ...state,
        requests: state.requests.map((request) => {
          if (request.id === payload) {
            return {
              ...request,
              checked: true,
            };
          }
          return request;
        }),
      };
    default:
      return state;
  }
};

export default requests;
