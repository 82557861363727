import { useCallback, useState } from 'react';

export const usePopup = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openPopup = useCallback(() => setIsOpen(true), []);

  const closePopup = useCallback(() => setIsOpen(false), []);

  const togglePopup = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  return {
    isOpen,
    openPopup,
    closePopup,
    togglePopup,
  };
};
