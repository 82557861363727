const fetchRequests =
  (pageNumber = 1, pageSize = 20) =>
  async (dispatch, _, { apiV1 }) => {
    dispatch(setRequestsLoading(true));
    await apiV1
      .get('/requests', {
        params: {
          pageNumber,
          pageSize,
        },
      })
      .then((response) => {
        dispatch(setRequests(response.data));
      })
      .catch((error) => {
        dispatch(setRequestsError(error));
      })
      .finally(() => {
        dispatch(setRequestsLoading(false));
      });
  };

const setRequestChecked =
  (id) =>
  async (dispatch, _, { apiV1 }) => {
    await apiV1.put(`/request/${id}`).then(() => {
      dispatch(setRequestCheckedSuccess(id));
    });
  };

const setRequests = (requests) => ({
  type: 'SET_REQUESTS',
  payload: requests,
});

const setRequestsLoading = (isLoading) => ({
  type: 'SET_REQUESTS_LOADING',
  payload: isLoading,
});

const setRequestsError = (error) => ({
  type: 'SET_REQUESTS_ERROR',
  payload: error,
});

const setRequestCheckedSuccess = (id) => ({
  type: 'SET_REQUEST_CHECKED_SUCCESS',
  payload: id,
});

export const requestsActions = {
  fetchRequests,
  setRequestChecked,
  setRequests,
  setRequestsLoading,
  setRequestsError,
  setRequestCheckedSuccess,
};
