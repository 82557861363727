import React from 'react';
import Pagination from 'react-js-pagination';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { Icon } from 'components';

export const PaginationComponent = ({ totalItemsCount, itemsCountPerPage = 20, activePage, history, searchText }) => {
  const location = useLocation();
  const mediaMobile = useMediaQuery('(max-width: 767.98px)');
  const pageRangeDisplayed = mediaMobile ? 3 : 5;

  const firstPageText = () => {
    return (
      <>
        <Icon icon={mediaMobile ? 'arrow-left-double' : 'arrow-left'} width={mediaMobile ? '34' : '24'} />
        <span className="pagination__label">First Page</span>
      </>
    );
  };

  const lastPageText = () => {
    return (
      <>
        <span className="pagination__label">Last Page</span>
        <Icon icon={mediaMobile ? 'arrow-right-double' : 'arrow-right'} width={mediaMobile ? '34' : '24'} />
      </>
    );
  };

  const handleChange = (pageNumber) => {
    window.scrollTo(0, 0);

    history.push(`?pageNumber=${pageNumber}`);

    if (location.pathname.includes('products') && searchText) {
      history.push(`?pageNumber=${pageNumber}&text=${searchText}`);
    }

    if (location.pathname.includes('users') && searchText) {
      history.push(`?pageNumber=${pageNumber}&userName=${searchText}`);
    }

    if (location.pathname.includes('ingredients') && searchText) {
      history.push(`?pageNumber=${pageNumber}&ingredientName=${searchText}`);
    }
  };

  return (
    totalItemsCount > itemsCountPerPage && (
      <nav className="pagination">
        <div className="wrapper">
          <Pagination
            activePage={activePage}
            itemsCountPerPage={itemsCountPerPage}
            totalItemsCount={totalItemsCount}
            pageRangeDisplayed={pageRangeDisplayed}
            onChange={handleChange}
            innerClass="pagination__list"
            itemClass="pagination__item"
            activeClass="pagination__item_active"
            itemClassFirst="pagination__item_first-page"
            itemClassPrev="pagination__item_prev-page"
            itemClassNext="pagination__item_next-page"
            itemClassLast="pagination__item_last-page"
            disabledClass="pagination__item_disabled"
            linkClass="pagination__link"
            activeLinkClass="pagination__link_active"
            prevPageText={<Icon icon="arrow-left" />}
            nextPageText={<Icon icon="arrow-right" />}
            firstPageText={firstPageText()}
            lastPageText={lastPageText()}
          />
        </div>
      </nav>
    )
  );
};
