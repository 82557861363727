import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

export const CustomDialog = ({ children, maxWidth = 458 }) => {
  const theme = createTheme({
    overrides: {
      MuiBackdrop: {
        root: {
          backgroundColor: 'rgba(39, 36, 36, 0.5)',
        },
      },
      MuiPaper: {
        rounded: {
          borderRadius: 8,
        },
        elevation24: {
          boxShadow: 'none',
        },
      },
      MuiDialog: {
        paper: {
          margin: 0,
        },
        paperWidthSm: {
          maxWidth: maxWidth,
        },
        paperFullWidth: {
          width: 'calc(100% - 32px)',
        },
      },
    },
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
