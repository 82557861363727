import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from 'hooks';

import { usersActions } from 'store/actions';

export const useUsers = ({ pageNumber, userName }) => {
  const { fetchUsers, setUsersError } = useActions(usersActions);

  const users = useSelector(({ users }) => users.users);
  const usersTotalCount = useSelector(({ users }) => users.totalCount);
  const isLoadingUsers = useSelector(({ users }) => users.isLoading);
  const errorUsers = useSelector(({ users }) => users.error);

  useEffect(() => {
    fetchUsers({ pageNumber, userName });

    return () => {
      setUsersError(null);
    };
  }, [fetchUsers, pageNumber, setUsersError, userName]);

  return {
    users,
    usersTotalCount,
    isLoadingUsers,
    errorUsers,
  };
};
