import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from 'hooks';

import { newsActions } from 'store/actions';

export const useNews = (pageNumber) => {
  const { fetchNews } = useActions(newsActions);

  const news = useSelector(({ news }) => news.news);
  const newsTotalCount = useSelector(({ news }) => news.totalCount);
  const isLoadingNews = useSelector(({ news }) => news.isLoading);
  const errorNews = useSelector(({ news }) => news.error);

  useEffect(() => {
    fetchNews(pageNumber);
  }, [fetchNews, pageNumber]);

  return {
    news,
    newsTotalCount,
    isLoadingNews,
    errorNews,
  };
};
