const initialState = {
  metadata: null,
  isLoading: false,
  error: null,
};

const metadata = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_METADATA':
      return {
        ...state,
        metadata: payload,
      };
    case 'SET_METADATA_LOADING':
      return {
        ...state,
        isLoading: payload,
      };
    case 'SET_METADATA_ERROR':
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
};

export default metadata;
