import React from 'react';
import classNames from 'classnames';
import defaultProductLogo from 'img/product/logo_product.svg';

export const PreviewUploadImage = ({ image, alt, isLoading, error, className, onUploadImage }) => {
  const handleLoadingImageError = (event) => (event.target.src = defaultProductLogo);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    await onUploadImage(formData, file.name);
  };

  if (isLoading) {
    return <p>Loading image...</p>;
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  return (
    <div className={classNames('preview', className)}>
      <img
        className="preview__img"
        src={image}
        alt={alt}
        width="300"
        height="300"
        loading="lazy"
        onError={handleLoadingImageError}
      />
      <label className="field-file product-form__field-file">
        <input
          className="field-file__input"
          type="file"
          name="imageLink"
          onChange={handleFileUpload}
          accept="image/*"
        />
        <span className="field-file__btn field-file__btn_contained" tabIndex="0" role="button">
          Select file
        </span>
      </label>
    </div>
  );
};
