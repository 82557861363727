import React from 'react';
import { ReportsItem, Error, Loading } from 'components';

export const ReportsList = ({ reports, isLoading, error }) => {
  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <div className="reports-list">
      <div className="wrapper">
        {reports?.length === 0 ? (
          <p>Not found</p>
        ) : (
          reports?.map((report) => <ReportsItem key={report.id} {...report} />)
        )}
      </div>
    </div>
  );
};
