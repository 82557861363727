import React from 'react';
import { UsersItem, Error, Loading } from 'components';

export const UsersList = ({ users, isLoading, error }) => {
  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <div className="users-list">
      <div className="wrapper">
        {users?.length === 0 ? <p>Not found</p> : users?.map((user) => <UsersItem key={user.id} {...user} />)}
      </div>
    </div>
  );
};
