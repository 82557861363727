const initialState = {
  ingredients: null,
  totalCount: null,
  error: null,
  isLoading: false,
};

const ingredients = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_INGREDIENTS':
      return {
        ...state,
        ingredients: payload.result,
        totalCount: payload.count,
      };
    case 'SET_INGREDIENTS_LOADING':
      return {
        ...state,
        isLoading: payload,
      };
    case 'SET_INGREDIENTS_ERROR':
      return {
        ...state,
        error: payload,
      };
    case 'DELETE_INGREDIENT': {
      const newIngredients = state.ingredients.filter((ingredient) => ingredient.id !== payload);

      return {
        ...state,
        ingredients: newIngredients,
      };
    }
    case 'ADD_INGREDIENT':
      return {
        ...state,
        ingredients: [...state.ingredients, payload],
      };
    case 'UPDATE_INGREDIENT': {
      const oldIngredients = state.ingredients.filter((ingredient) => ingredient.id !== payload.id);

      return {
        ...state,
        ingredients: [...oldIngredients, payload],
      };
    }
    default:
      return state;
  }
};

export default ingredients;
