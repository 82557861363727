import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { App } from 'App';
import { Toasts } from 'components';

import { store } from 'store/store';

import { theme } from 'shared/theme';

import './scss/main.scss';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
        <Toasts />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
);
