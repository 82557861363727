import React from 'react';

export const Keys = ({ keys }) => {
  return (
    <div className="keys">
      <ul className="keys__list">
        {keys?.map((key, index) => {
          return (
            <li key={index} className="keys__item">
              {key}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
