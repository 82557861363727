import React from 'react';
import { Icon } from 'components';

export const SearchField = ({ className, children, value, onChange, autoComplete = 'off', onSubmit, ...attrs }) => {
  const classes = ['field-search'];

  if (className) {
    classes.push(className);
  }

  return (
    <form className={classes.join(' ')} autoComplete={autoComplete} onSubmit={onSubmit}>
      <label className="field-search__input-wrap">
        <input className="field-search__input" type="search" value={value} onChange={onChange} {...attrs} />
        <Icon className="field-search__icon" icon="search" width="18" height="18" aria-label="Search" />
      </label>
      {children}
    </form>
  );
};
