const fetchUsers =
  ({ pageNumber = 1, pageSize = 20, userName }) =>
  async (dispatch, _, { apiV1 }) => {
    dispatch(setUsersLoading(true));
    await apiV1
      .get('/users', {
        params: {
          pageNumber,
          pageSize,
          userName,
        },
      })
      .then((response) => {
        dispatch(setUsers(response.data));
      })
      .catch((error) => {
        dispatch(setUsersError(error));
      })
      .finally(() => {
        dispatch(setUsersLoading(false));
      });
  };

const setUsers = (users) => ({
  type: 'SET_USERS',
  payload: users,
});

const setUsersLoading = (isLoading) => ({
  type: 'SET_USERS_LOADING',
  payload: isLoading,
});

const setUsersError = (error) => ({
  type: 'SET_USERS_ERROR',
  payload: error,
});

export const usersActions = {
  fetchUsers,
  setUsers,
  setUsersLoading,
  setUsersError,
};
