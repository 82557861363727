import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { TAGS } from './tags';

export const baseApi = createApi({
  baseQuery: fetchBaseQuery(),
  reducerPath: 'api',
  endpoints: () => ({}),
  keepUnusedDataFor: 30,
  tagTypes: TAGS,
});