import React, { useState } from 'react';
import { Footer, Header, MobileMenu } from 'components';

export const Layout = ({ children }) => {
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);

  const handleClickBurger = () => {
    setIsOpenMobileMenu(!isOpenMobileMenu);
    document.body.classList.toggle('overflow-hidden');
  };

  const closeMobileMenu = () => {
    setIsOpenMobileMenu(false);
    document.body.classList.remove('overflow-hidden');
  };

  return (
    <>
      <div className="page__content">
        <MobileMenu isOpenMobileMenu={isOpenMobileMenu} onClickItemMenu={closeMobileMenu} />
        <Header isOpenMobileMenu={isOpenMobileMenu} onClickLogo={closeMobileMenu} onClickBurger={handleClickBurger} />
        <main className="page-body">{children}</main>
      </div>
      <div className="page__footer-wrapper">
        <Footer />
      </div>
    </>
  );
};
