import React from 'react';
import { RequestsItem, Error, Loading } from 'components';

export const RequestsList = ({ requests, isLoading, error }) => {
  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <div className="requests-list">
      <div className="wrapper">
        {requests?.length === 0 ? (
          <p>Not found</p>
        ) : (
          requests?.map((request) => <RequestsItem key={request.id} {...request} />)
        )}
      </div>
    </div>
  );
};
