import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from 'hooks';

import { categoriesActions } from 'store/actions';

export const useCategories = () => {
  const { fetchCategories, setCategoriesError } = useActions(categoriesActions);

  const categories = useSelector(({ categories }) => categories.categories);
  const isLoadingCategories = useSelector(({ categories }) => categories.isLoading);
  const errorCategories = useSelector(({ categories }) => categories.error);

  useEffect(() => {
    fetchCategories();

    return () => {
      setCategoriesError(null);
    };
  }, [fetchCategories, setCategoriesError]);

  return {
    categories,
    isLoadingCategories,
    errorCategories,
  };
};
