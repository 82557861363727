import { configureStore, combineReducers } from '@reduxjs/toolkit';

import createAPI from 'api';

import { baseApi } from 'shared/api';

import { oldReducers } from './reducers';

const apiV1 = createAPI((...args) => store.dispatch(...args), '/api/v1');
const apiV2 = createAPI((...args) => store.dispatch(...args), '/api/v2');

export const store = configureStore({
  reducer: combineReducers({
    ...oldReducers,
    [baseApi.reducerPath]: baseApi.reducer,
  }),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    thunk: {
      extraArgument: {apiV1, apiV2}
    }
  }).concat(baseApi.middleware),
});

