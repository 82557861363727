const fetchNews =
  (pageNumber = 1, pageSize = 20) =>
  async (dispatch, _, { apiV1 }) => {
    dispatch(setNewsLoading(true));
    await apiV1
      .get('/news', {
        params: {
          pageNumber,
          pageSize,
        },
      })
      .then((response) => {
        dispatch(setNews(response.data));
      })
      .catch((error) => {
        dispatch(setNewsError(error));
      })
      .finally(() => {
        dispatch(setNewsLoading(false));
      });
  };

const setNews = (News) => ({
  type: 'SET_NEWS',
  payload: News,
});

const setNewsLoading = (isLoading) => ({
  type: 'SET_NEWS_LOADING',
  payload: isLoading,
});

const setNewsError = (error) => ({
  type: 'SET_NEWS_ERROR',
  payload: error,
});

export const newsActions = {
  fetchNews,
  setNews,
  setNewsLoading,
  setNewsError,
};
