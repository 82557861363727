import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { requestsActions } from 'store/actions';
import { useActions } from 'hooks';
import { Icon } from 'components';

export const RequestsItem = ({ id, user, comment, link, checked }) => {
  const { setRequestChecked } = useActions(requestsActions);
  const [isOpen, setIsOpen] = useState(false);

  const handleClickShowMore = async () => {
    try {
      setIsOpen(!isOpen);

      if (!checked) {
        await setRequestChecked(id);
      }
    } catch (error) {
      toast.error(error.message, {
        autoClose: false,
      });
    }
  };

  return (
    <div className="request collapse">
      <div className="collapse__header request__header">
        <div className="request__badge">
          <Icon
            icon={checked ? 'exclamation-close' : 'exclamation-open'}
            width="16"
            height="16"
            className={checked ? 'request__icon request__icon_close' : 'request__icon request__icon_open'}
          />
        </div>
        <div className="request__info">
          <span className="request__user">{user}</span> requested to add a product.{' '}
          <button type="button" className="request__btn" onClick={handleClickShowMore}>
            Show more
          </button>
        </div>
      </div>
      <div className={isOpen ? 'collapse__content collapse__content_open' : 'collapse__content'}>
        <p>Description: {comment}</p>
        <p>
          Link:{' '}
          <a className="request__link" href={link}>
            {link}
          </a>
        </p>
        <Link to="/add-product" className="btn btn_contained" role="button" title="Add new product">
          <span className="btn__label">Add new product</span>
        </Link>
      </div>
    </div>
  );
};
