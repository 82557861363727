import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from 'hooks';

import { userActions } from 'store/actions';

export const useUser = (userName) => {
  const { fetchUser, setUserError, setUser } = useActions(userActions);

  const user = useSelector(({ user }) => user.user);
  const isLoadingUser = useSelector(({ user }) => user.isLoading);
  const errorUser = useSelector(({ user }) => user.error);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchUser(userName);

    return () => {
      setUserError(null);
      setUser(null);
    };
  }, [fetchUser, setUser, setUserError, userName]);

  return {
    user,
    isLoadingUser,
    errorUser,
  };
};
