import * as yup from 'yup';

export const productSchema = yup.object({
  name: yup.string().required(),
  brand: yup.string().required(),
  country: yup.string().required(),
  producer: yup.string().required(),
  category: yup.string().required(),
  subCategory: yup.string().required(),
  additionalInfo: yup.string().required(),
  ingredients: yup.string().required(),
});

export const addProductSchema = yup
  .object({
    userRequestedName: yup.string(),
  })
  .concat(productSchema);
