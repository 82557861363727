import {
  Autocomplete,
  CircularProgress,
  TextField,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';

export const FormAsyncAutocomplete = ({
                                        name,
                                        label,
                                        placeholder,
                                        options,
                                        isLoading = false,
                                        ...rest
                                      }) => {

  const { getFieldState, setValue, formState } = useFormContext();

  const { error } = getFieldState(name, formState);

  const handleChange = (_, option) => {
    setValue(name, option, { shouldValidate: true });
  };

  return (
    <Autocomplete
      fullWidth
      disablePortal
      options={options}
      id={name}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) => option.label}
      onChange={handleChange}
      {...rest}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading && (
                  <CircularProgress color="inherit" size="1.2rem" />
                )}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          label={label}
          InputLabelProps={{ shrink: true }}
          placeholder={placeholder}
          error={!!error}
          helperText={error?.message}
        />
      )}
    />
  );
};
