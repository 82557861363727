import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from 'hooks';

import { articleActions } from 'store/actions';

export const useArticle = (id) => {
  const { fetchArticle, setArticleError, setArticleUploadImageError, setArticleImage } = useActions(articleActions);

  const article = useSelector(({ article }) => article.article);
  const isLoadingArticle = useSelector(({ article }) => article.isLoading);
  const errorArticle = useSelector(({ article }) => article.error);

  useEffect(() => {
    fetchArticle(id);

    return () => {
      setArticleError(null);
      setArticleUploadImageError(null);
      setArticleImage('');
    };
  }, [fetchArticle, id, setArticleError, setArticleImage, setArticleUploadImageError]);

  return {
    article,
    isLoadingArticle,
    errorArticle,
  };
};
