const initialState = {
  products: null,
  totalCount: null,
  error: null,
  isLoading: false,
};

const products = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_PRODUCTS':
      return {
        ...state,
        products: payload.result,
        totalCount: payload.count,
      };
    case 'SET_PRODUCTS_LOADING':
      return {
        ...state,
        isLoading: payload,
      };
    case 'SET_PRODUCTS_ERROR':
      return {
        ...state,
        products: null,
        totalCount: null,
        error: payload,
      };
    default:
      return state;
  }
};

export default products;
