import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from 'hooks';
import { parseBrands } from 'utils';

import { brandsActions } from 'store/actions';

export const useBrands = () => {
  const { fetchBrands, setBrandsError } = useActions(brandsActions);

  const brands = useSelector(({ brands }) => brands.brands);
  const isLoadingBrands = useSelector(({ brands }) => brands.isLoading);
  const errorBrands = useSelector(({ brands }) => brands.error);

  const sortedBrands = useMemo(() => {
    return brands?.toSorted((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())) || [];
  }, [brands])

  const { brandNames, allBrands } = useMemo(() => parseBrands(brands), [brands]);

  useEffect(() => {
    fetchBrands();

    return () => {
      setBrandsError(null);
    };
  }, [fetchBrands, setBrandsError]);

  return {
    brands,
    sortedBrands,
    brandNames,
    allBrands,
    isLoadingBrands,
    errorBrands,
  };
};
