const fetchUser =
  (userName) =>
  async (dispatch, _, { apiV1 }) => {
    dispatch(setUserLoading(true));
    await apiV1
      .get(`/user/${userName}`)
      .then((response) => {
        dispatch(setUser(response.data));
      })
      .catch((error) => {
        dispatch(setUserError(error));
      })
      .finally(() => {
        dispatch(setUserLoading(false));
      });
  };

const deleteUser =
  (id) =>
  async (dispatch, _, { apiV1 }) => {
    await apiV1.delete(`/user/${id}`);
  };

const addToShelf =
  ({ userId, productId }) =>
  async (dispatch, _, { apiV1 }) => {
    await apiV1.post(`/user/${userId}/shelf/${productId}`);
  };

const setUser = (user) => ({
  type: 'SET_USER',
  payload: user,
});

const setUserLoading = (isLoading) => ({
  type: 'SET_USER_LOADING',
  payload: isLoading,
});

const setUserError = (error) => ({
  type: 'SET_USER_ERROR',
  payload: error,
});

export const userActions = {
  fetchUser,
  deleteUser,
  addToShelf,
  setUser,
  setUserLoading,
  setUserError,
};
