export const getUserFromId = (users, id) => users.filter((user) => user.id === id)[0];

export const getBrandFromId = (brands, id) => brands.filter((brand) => brand.id === id)[0];

export const getBrandFromBrands = (brands, brandName) => brands.filter((brand) => brand.name === brandName)[0];

export const getCountryFromBrands = (brands, brandName) =>
  brands?.filter((brand) => brand.name === brandName)[0]?.country;

export const getProducerFromBrands = (brands, brandName) =>
  brands?.filter((brand) => brand.name === brandName)[0]?.producer;

export const getCategoryFromCategories = (categories, categoryName) =>
  categories.filter((category) => category.name === categoryName)[0];

export const getSubcategoriesFromCategory = (categories, categoryName) =>
  categories?.filter((category) => category.name === categoryName)[0]?.subCategories;

export const getSubcategoryFromSubcategories = (categories, categoryName, subcategoryName) =>
  getSubcategoriesFromCategory(categories, categoryName).filter(
    (subcategory) => subcategory.name === subcategoryName,
  )[0];

export const parseBrands = (brands) => {
  let allBrands = {};
  let brandNames;

  brands?.forEach((brand) => {
    const key = isNaN(brand.name.charAt(0)) ? brand.name.charAt(0).toUpperCase() : '0-9';
    allBrands[key] ? allBrands[key].push(brand) : (allBrands[key] = [brand]);
  });

  Object.keys(allBrands).forEach((key) => {
    allBrands[key].sort((a, b) => {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });
  });

  const names = Object.keys(allBrands).sort();
  [names[1], names[0]] = [names[0], names[1]];
  brandNames = ['All', ...names];

  return {
    brandNames,
    allBrands,
  };
};

export const getSearchParams = (url) => {
  const parsedUrl = new URLSearchParams(url);
  const pageNumber = Number(parsedUrl.get('pageNumber')) || 1;
  const text = parsedUrl.get('text');
  const userName = parsedUrl.get('userName');
  const brand = parsedUrl.get('brand') || 'All';
  const ingredientName = parsedUrl.get('ingredientName');

  return {
    pageNumber,
    text,
    brand,
    userName,
    ingredientName,
  };
};
