import { useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { useIngredientsQuery } from 'entities/ingredient';

import { FormAsyncAutocomplete } from 'shared/ui';

export const IngredientAsyncAutocomplete = (props) => {
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 500);

  const { data: ingredientsData = { result: [] }, isFetching: isIngredientsFetching } = useIngredientsQuery({
    nameLike: debouncedSearch,
  });

  const ingredientsOptions = useMemo(() => {
    return ingredientsData.result.map(({ name, id }) => ({ label: name, value: id }));
  }, [ingredientsData.result]);

  return (
    <FormAsyncAutocomplete
      disablePortal={false}
      onInputChange={(_, value) => setSearch(value)}
      filterOptions={(values) => values}
      isLoading={isIngredientsFetching}
      {...props}
      options={ingredientsOptions}
    />
  );
};
