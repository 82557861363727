export const sunEditorOptions = {
  height: 'auto',
  imageUrlInput: false,
  imageResizing: false,
  imageRotation: false,
  shortcutsHint: false,
  showPathLabel: false,
  mediaAutoSelect: false,
  shortcutsDisable: true,
  minHeight: 300,
  linkProtocol: 'http://',
  buttonList: [
    ['bold', 'italic', 'strike', 'align'],
    ['list'],
    ['link'],
    ['image'],
    ['fullScreen'],
    ['undo'],
    ['%415', [['bold', 'italic', 'strike', 'align', 'list', 'link', 'image', 'undo']]],
    [
      '%321',
      [
        ['bold', 'italic', 'strike', 'undo'],
        [':e-More Line-default.more_horizontal', 'align', 'list', 'link', 'image'],
      ],
    ],
  ],
};
