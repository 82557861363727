import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import styles from './Button.module.scss';

export const Button = ({ to, variant, children, disabled, block, onClick, className, ...attrs }) => {
  if (to && disabled) {
    attrs['tabIndex'] = '-1';
    attrs['aria-disabled'] = 'true';
  }

  return to ? (
    <Link
      className={classNames(styles.btn, className, {
        [styles.btn_block]: block,
        [styles.btn_contained]: variant === 'contained',
        [styles.btn_outlined]: variant === 'outlined',
        [styles.btn_disabled]: to && disabled,
      })}
      to={to}
      role="button"
      onClick={onClick}
      {...attrs}
    >
      <span className={styles.btn__label}>{children}</span>
    </Link>
  ) : (
    <button
      type="button"
      className={classNames(styles.btn, className, {
        [styles.btn_block]: block,
        [styles.btn_contained]: variant === 'contained',
        [styles.btn_outlined]: variant === 'outlined',
        [styles.btn_disabled]: disabled,
      })}
      onClick={onClick}
      {...attrs}
      disabled={disabled}
    >
      <span className={styles.btn__label}>{children}</span>
    </button>
  );
};
