import { lazy } from 'react';

const Welcome = lazy(() =>
  import('pages/Welcome').then((mod) => ({
    default: mod.Welcome,
  })),
);
const Users = lazy(() =>
  import('pages/Users').then((mod) => ({
    default: mod.Users,
  })),
);
const AddBrand = lazy(() =>
  import('pages/AddBrand').then((mod) => ({
    default: mod.AddBrand,
  })),
);
const Brands = lazy(() =>
  import('pages/Brands').then((mod) => ({
    default: mod.Brands,
  })),
);
const Brand = lazy(() =>
  import('pages/Brand').then((mod) => ({
    default: mod.Brand,
  })),
);
const User = lazy(() =>
  import('pages/User').then((mod) => ({
    default: mod.User,
  })),
);
const Seo = lazy(() =>
  import('pages/Seo').then((mod) => ({
    default: mod.Seo,
  })),
);
const AddProduct = lazy(() =>
  import('pages/AddProduct').then((mod) => ({
    default: mod.AddProduct,
  })),
);
const Article = lazy(() =>
  import('pages/Article').then((mod) => ({
    default: mod.Article,
  })),
);
const AddNews = lazy(() =>
  import('pages/AddNews').then((mod) => ({
    default: mod.AddNews,
  })),
);
const News = lazy(() =>
  import('pages/News').then((mod) => ({
    default: mod.News,
  })),
);
const NotFound = lazy(() =>
  import('pages/NotFound').then((mod) => ({
    default: mod.NotFound,
  })),
);
const Product = lazy(() =>
  import('pages/Product').then((mod) => ({
    default: mod.Product,
  })),
);
const Products = lazy(() =>
  import('pages/Products').then((mod) => ({
    default: mod.Products,
  })),
);
const Reports = lazy(() =>
  import('pages/Reports').then((mod) => ({
    default: mod.Reports,
  })),
);
const Requests = lazy(() =>
  import('pages/Requests').then((mod) => ({
    default: mod.Requests,
  })),
);
const Ingredients = lazy(() =>
  import('pages/Ingredients').then((mod) => ({
    default: mod.Ingredients,
  })),
);

export const routes = [
  {
    path: '/',
    component: Welcome,
    exact: true,
  },
  {
    path: '/products',
    component: Products,
    exact: true,
  },
  {
    path: '/add-product',
    component: AddProduct,
    exact: true,
  },
  {
    path: '/product/:productName',
    component: Product,
    exact: true,
  },
  {
    path: '/article/:id',
    component: Article,
    exact: true,
  },
  {
    path: '/users',
    component: Users,
    exact: true,
  },
  {
    path: '/requests',
    component: Requests,
    exact: true,
  },
  {
    path: '/reports',
    component: Reports,
    exact: true,
  },
  {
    path: '/news',
    component: News,
    exact: true,
  },
  {
    path: '/add-news',
    component: AddNews,
    exact: true,
  },
  {
    path: '/add-brand',
    component: AddBrand,
    exact: true,
  },
  {
    path: '/brands',
    component: Brands,
    exact: true,
  },
  {
    path: '/brand/:id',
    component: Brand,
    exact: true,
  },
  {
    path: '/user/:userName',
    component: User,
    exact: true,
  },
  {
    path: '/seo',
    component: Seo,
    exact: true,
  },
  {
    path: '/ingredients',
    component: Ingredients,
    exact: true,
  },
  {
    path: '*',
    component: NotFound,
    exact: true,
  },
];
