import React from 'react';

export const Footer = () => {
  return (
    <div className="page-footer">
      <div className="wrapper">
        <span className="page-footer__copyright">All rights reserved — {new Date().getFullYear()} © Skintory</span>
      </div>
    </div>
  );
};
