import { BrowserRouter as Router } from 'react-router-dom';

import { AppRouter, Layout } from 'components';

export const App = () => {
  return (
    <Router basename="/admin">
      <Layout>
        <AppRouter />
      </Layout>
    </Router>
  );
};
