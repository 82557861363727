import React from 'react';
import { Link } from 'react-router-dom';
import defaultProductLogo from 'img/product/logo_product.svg';

export const CatalogItem = ({ imageLink, name, brand, url }) => {
  const handleError = (event) => (event.target.src = defaultProductLogo);

  return (
    <article className="product catalog-list__product">
      <div className="product__wrap">
        <div className="product__img-wrap">
          <Link to={`/product/${url}`} className="product__img-link">
            <img
              className="product__img"
              src={imageLink || ''}
              alt={name}
              width="182"
              height="182"
              loading="lazy"
              onError={handleError}
            />
          </Link>
        </div>
        <h2 className="product__title">
          <Link to={`/product/${url}`} className="product__link">
            {`${brand.name}, ${name}`}
          </Link>
        </h2>
      </div>
    </article>
  );
};
