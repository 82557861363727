import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { brandActions } from 'store/actions';
import { brandSchema } from 'utils';
import { useActions } from 'hooks';
import { Button, FormTextField } from 'components';

export const AddBrandForm = () => {
  const { addBrand } = useActions(brandActions);
  const addBrandForm = useForm({
    resolver: yupResolver(brandSchema),
    mode: 'onChange',
  });

  const onSubmit = async (data) => {
    try {
      await addBrand(data);
      toast.success(`"${data.name}" has been successfully added`, {
        autoClose: false,
      });
      addBrandForm.reset();
    } catch (error) {
      toast.error(error.message, {
        autoClose: false,
      });
    }
  };

  return (
    <FormProvider {...addBrandForm}>
      <form action="#" className="form brand-form" method="post" onSubmit={addBrandForm.handleSubmit(onSubmit)}>
        <fieldset className="form__fieldset brand-form__fieldset">
          <FormTextField label="Name" name="name" />
          <FormTextField label="Country" name="country" />
          <FormTextField label="Manufacturer" name="producer" />
        </fieldset>
        <div className="field-actions">
          <Button type="submit" variant="contained" className="field-actions__btn" title="Add brand">
            Add brand
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
