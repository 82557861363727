import React from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

const links = [
  { name: 'Products', href: '/products' },
  { name: 'Users', href: '/users' },
  { name: 'Reports', href: '/reports' },
  { name: 'Requests', href: '/requests' },
  { name: 'News', href: '/news' },
  { name: 'Brands', href: '/brands' },
  { name: 'Seo', href: '/seo' },
  { name: 'Ingredients', href: '/ingredients' },
];

export const MainNav = ({ className, onClickItemMenu }) => {
  return (
    <div className={classNames('main-nav', className)}>
      <ul className="main-nav__list">
        {links.map(({ name, href }) => (
          <li className="main-nav__item" key={href}>
            <NavLink
              to={href}
              className="main-nav__link"
              activeClassName="main-nav__link_active"
              onClick={onClickItemMenu}
            >
              {name}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};
