const fetchBrands =
  () =>
  async (dispatch, _, { apiV1 }) => {
    dispatch(setBrandsLoading(true));
    await apiV1
      .get('/brands')
      .then((response) => {
        dispatch(setBrands(response.data));
      })
      .catch((error) => {
        dispatch(setBrandsError(error));
      })
      .finally(() => {
        dispatch(setBrandsLoading(false));
      });
  };

const setBrands = (brands) => ({
  type: 'SET_BRANDS',
  payload: brands,
});

const setBrandsLoading = (isLoading) => ({
  type: 'SET_BRANDS_LOADING',
  payload: isLoading,
});

const setBrandsError = (error) => ({
  type: 'SET_BRANDS_ERROR',
  payload: error,
});

export const brandsActions = {
  fetchBrands,
  setBrands,
  setBrandsLoading,
  setBrandsError,
};
