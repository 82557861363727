import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from 'hooks';

import { productActions } from 'store/actions';

export const useProduct = (productName) => {
  const { fetchProduct, setProductError, setProduct } = useActions(productActions);

  const product = useSelector(({ product }) => product.product);
  const isLoadingProduct = useSelector(({ product }) => product.isLoading);
  const errorProduct = useSelector(({ product }) => product.error);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchProduct(productName);

    return () => {
      setProductError(null);
      setProduct({
        imageLink: '',
        name: '',
        brand: {
          name: '',
          country: '',
          producer: '',
        },
        category: {
          name: '',
          subCategories: [],
        },
        subCategory: {
          name: '',
        },
        additionalInfo: '',
        ingredients: '',
      });
    };
  }, [fetchProduct, productName, setProduct, setProductError]);

  return {
    product,
    isLoadingProduct,
    errorProduct,
  };
};
