import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { productActions } from 'store/actions';
import { useActions } from 'hooks';
import {
  getBrandFromBrands,
  getCategoryFromCategories,
  getCountryFromBrands,
  getProducerFromBrands,
  getSubcategoriesFromCategory,
  getSubcategoryFromSubcategories,
  addProductSchema,
} from 'utils';
import { Button, SelectField, FormTextField } from 'components';

export const AddProductForm = ({ product, categories, brands }) => {
  const { addProduct, setProductImage } = useActions(productActions);

  const [arraySubcategories, setArraySubcategories] = useState([]);

  const addProductForm = useForm({
    resolver: yupResolver(addProductSchema),
    mode: 'onChange',
  });

  const watchBrand = addProductForm.watch('brand');
  const watchCategory = addProductForm.watch('category');

  const onSubmit = async (data) => {
    try {
      await addProduct({
        imageLink: product.imageLink,
        name: data.name,
        brand: getBrandFromBrands(brands, data.brand),
        country: getCountryFromBrands(brands, data.brand),
        producer: getProducerFromBrands(brands, data.brand),
        category: getCategoryFromCategories(categories, data.category),
        subCategory: getSubcategoryFromSubcategories(categories, data.category, data.subCategory),
        additionalInfo: data.additionalInfo,
        ingredients: data.ingredients.trim().split(' | '),
        userRequestedName: data.userRequestedName,
        seoMetadataTitle: data.seoMetadataTitle,
        seoMetadataDescription: data.seoMetadataDescription,
        amazonUrl: data.amazonUrl,
      });
      toast.success(`"${product.name}" has been successfully added`, {
        autoClose: false,
      });
      addProductForm.reset();
    } catch (error) {
      toast.error(error.message, {
        autoClose: false,
      });
    }
  };

  useEffect(() => {
    setArraySubcategories(getSubcategoriesFromCategory(categories, watchCategory));
  }, [watchCategory, categories]);

  useEffect(() => {
    if (watchBrand) {
      addProductForm.setValue('country', getCountryFromBrands(brands, watchBrand), {
        shouldValidate: true,
      });
      addProductForm.setValue('producer', getProducerFromBrands(brands, watchBrand), {
        shouldValidate: true,
      });
    }
  }, [watchBrand, brands, addProductForm]);

  useEffect(() => {
    return () => {
      setProductImage('');
    };
  }, [setProductImage]);

  return (
    <>
      <FormProvider {...addProductForm}>
        <form action="#" className="form product-form" method="post" onSubmit={addProductForm.handleSubmit(onSubmit)}>
          <fieldset className="form__fieldset product-form__fieldset">
            <legend className="form__legend product-form__legend">Product Information</legend>
            <FormTextField
              label="Name"
              name="name"
              className="product-form__field-text product-form__field-text_name"
            />
            <SelectField
              className="product-form__field-select product-form__field-select_brand"
              label="Brand"
              name="brand"
              options={brands.map((brand) => brand.name)}
              error={addProductForm.formState.errors.brand}
              helperText={addProductForm.formState.errors.brand?.message}
              {...addProductForm.register('brand')}
            />
            <FormTextField
              label="Country"
              name="country"
              className="product-form__field-text product-form__field-text_country"
              disabled
            />
            <FormTextField
              label="Manufacturer"
              name="producer"
              className="product-form__field-text product-form__field-text_producer"
              disabled
            />
            <SelectField
              className="product-form__field-select product-form__field-select_category"
              label="Category"
              name="category"
              options={categories.map((category) => category.name)}
              error={addProductForm.formState.errors.category}
              helperText={addProductForm.formState.errors.category?.message}
              {...addProductForm.register('category')}
            />
            <SelectField
              className="product-form__field-select product-form__field-select_subcategory"
              label="Sub category"
              name="subCategory"
              options={arraySubcategories?.map((subCategory) => subCategory.name)}
              error={addProductForm.formState.errors.subCategory}
              helperText={addProductForm.formState.errors.subCategory?.message}
              disabled={!arraySubcategories}
              {...addProductForm.register('subCategory')}
            />
            <FormTextField
              label="Description"
              name="additionalInfo"
              className="product-form__field-text product-form__field-text_description"
              multiline
            />
            <FormTextField
              label="Ingredients"
              name="ingredients"
              className="product-form__field-text product-form__field-text_ingredients"
              multiline
            />
            <FormTextField
              label="Amazon URL"
              name="amazonUrl"
              className="product-form__field-text product-form__field-text_amazon-url"
            />
            <FormTextField
              label="Add To User's Shelf"
              name="userRequestedName"
              className="product-form__field-text product-form__field-text_user-requested-name"
            />
          </fieldset>
          <fieldset className="form__fieldset product-form__fieldset product-form__fieldset_seo">
            <legend className="form__legend product-form__legend">SEO Information</legend>
            <FormTextField label="Meta title" name="seoMetadataTitle" className="product-form__field-text" />
            <FormTextField
              label="Meta Description"
              name="seoMetadataDescription"
              className="product-form__field-text"
            />
          </fieldset>
          <div className="field-actions">
            <Button type="submit" variant="contained" className="field-actions__btn" title="Add product">
              Add product
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};
