import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { getSearchParams } from 'utils';
import { Error, Loading } from 'components';

export const BrandsList = ({ brands, brandNames, history, isLoading, error, location }) => {
  const { brand: currentBrandName } = getSearchParams(location.search);

  const filteredBrandNames =
    currentBrandName === 'All' ? brandNames.filter((name) => name !== 'All') : [currentBrandName];

  const handleBrandLink = (event) => {
    event.preventDefault();

    history.push({
      search: `?brand=${encodeURIComponent(event.target.dataset.value)}`,
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <div className="brands">
      <div className="wrapper brands__wrapper">
        <nav className="brands__nav mb:hidden">
          {brandNames.map((name, index) => (
            <span
              tabIndex="0"
              key={index}
              className={classNames('brands__link', {
                brands__link_active: name === currentBrandName,
              })}
              data-value={name}
              onClick={handleBrandLink}
              onKeyDown={handleBrandLink}
            >
              {name}
            </span>
          ))}
        </nav>
        <div className="brands__body">
          <nav className="brands__nav hidden mb:block">
            {brandNames.map((name, index) => {
              return (
                <span
                  tabIndex="0"
                  key={index}
                  className={classNames('brands__link', {
                    brands__link_active: name === currentBrandName,
                  })}
                  data-value={name}
                  onClick={handleBrandLink}
                >
                  {name}
                </span>
              );
            })}
          </nav>
          <div className="brands__sections">
            {filteredBrandNames.map((name, index) => {
              return (
                <section className="brands__section" id={name} key={index}>
                  <h2 className="brands__title">{name}</h2>
                  <div className="brands__list">
                    {brands &&
                      brands[name]?.map(({ id, name }, index) => {
                        return (
                          <Link to={`/brand/${id}`} className="brands__name" key={index}>
                            {name}
                          </Link>
                        );
                      })}
                  </div>
                </section>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
