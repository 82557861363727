import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from 'hooks';

import { requestsActions } from 'store/actions';

export const useRequests = (pageNumber) => {
  const { fetchRequests, setRequestsError } = useActions(requestsActions);

  const requests = useSelector(({ requests }) => requests.requests);
  const requestsTotalCount = useSelector(({ requests }) => requests.totalCount);
  const isLoadingRequests = useSelector(({ requests }) => requests.isLoading);
  const errorRequests = useSelector(({ requests }) => requests.error);

  useEffect(() => {
    fetchRequests(pageNumber);

    return () => {
      setRequestsError(null);
    };
  }, [fetchRequests, pageNumber, setRequestsError]);

  return {
    requests,
    requestsTotalCount,
    isLoadingRequests,
    errorRequests,
  };
};
