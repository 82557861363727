const fetchProduct =
  (productName) =>
  async (dispatch, _, { apiV2 }) => {
    dispatch(setProductLoading(true));
    await apiV2
      .get(`/products/${productName}`)
      .then((response) => {
        dispatch(
          setProduct({
            id: response.data.id,
            imageLink: response.data.imageLink,
            name: response.data.name,
            brand: response.data.brand,
            category: response.data.category,
            subCategory: response.data.subCategory,
            additionalInfo: response.data.additionalInfo,
            ingredients: response.data.ingredients.join(' | '),
            seoMetadataTitle: response.data.seoMetadataTitle,
            seoMetadataDescription: response.data.seoMetadataDescription,
            amazonUrl: response.data.amazonUrl,
          }),
        );
      })
      .catch((error) => {
        dispatch(setProductError(error));
      })
      .finally(() => {
        dispatch(setProductLoading(false));
      });
  };

const uploadImageProduct =
  (formData) =>
  async (dispatch, _, { apiV1 }) => {
    dispatch(setProductUploadImageLoading(true));
    await apiV1
      .post('/product/pictureUpload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        dispatch(setProductImage(response.data));
      })
      .catch((error) => {
        dispatch(setProductUploadImageError(error));
      })
      .finally(() => {
        dispatch(setProductUploadImageLoading(false));
      });
  };

const deleteProduct =
  (id) =>
  async (dispatch, _, { apiV1 }) => {
    await apiV1.delete(`/product/${id}`);
  };

const addProduct =
  (product) =>
  async (dispatch, _, { apiV1 }) => {
    await apiV1.post('/product', product);
  };

const updateProduct =
  (product) =>
  async (dispatch, _, { apiV1 }) => {
    await apiV1.put('/product', product);
  };

const setProduct = (product) => ({
  type: 'SET_PRODUCT',
  payload: product,
});

const setProductImage = (imageLink) => ({
  type: 'SET_PRODUCT_IMAGE',
  payload: imageLink,
});

const setProductLoading = (isLoading) => ({
  type: 'SET_PRODUCT_LOADING',
  payload: isLoading,
});

const setProductError = (error) => ({
  type: 'SET_PRODUCT_ERROR',
  payload: error,
});

const setProductUploadImageLoading = (isLoading) => ({
  type: 'SET_PRODUCT_UPLOAD_IMAGE_LOADING',
  payload: isLoading,
});

const setProductUploadImageError = (error) => ({
  type: 'SET_PRODUCT_UPLOAD_IMAGE_ERROR',
  payload: error,
});

export const productActions = {
  fetchProduct,
  uploadImageProduct,
  deleteProduct,
  addProduct,
  updateProduct,
  setProduct,
  setProductImage,
  setProductLoading,
  setProductError,
  setProductUploadImageLoading,
  setProductUploadImageError,
};
