import * as yup from 'yup';

export const editIngredientSchema = yup.object({
  name: yup.string().required(),
  description: yup.string().nullable(),
  triaged: yup.boolean().nullable(),
  masterIngredient: yup.object().nullable(),
});

export const buildDefaultValues = (ingredient, masterIngredient) => {
  const staticFormData = {
    name: ingredient.name,
    description: ingredient.description,
    triaged: Boolean(ingredient.triaged),
    masterIngredient: null,
  };

  if (masterIngredient) {
    return { ...staticFormData, masterIngredient: { label: masterIngredient.name, value: masterIngredient.id } };
  }

  return staticFormData;
};

export const buildFormDataToSubmit = (formData) => {
  const { name, triaged, description, masterIngredient } = formData;

  return {
    name,
    triaged,
    description,
    masterIngredient: masterIngredient ? { id: masterIngredient.value } : null
  }
};
