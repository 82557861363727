import React from 'react';
import { useFormContext } from 'react-hook-form';
import { TextField } from 'components';

export const FormTextField = ({ label, name, disabled, multiline, className }) => {
  const {
    formState: { errors },
    register,
  } = useFormContext();

  return (
    <TextField
      className={className}
      label={label}
      name={name}
      error={errors[name]}
      helperText={errors[name]?.message}
      floating
      disabled={disabled}
      multiline={multiline}
      {...register(name)}
    />
  );
};
