import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from 'hooks';

import { seoActions } from 'store/actions';

export const useSeo = () => {
  const { fetchMetadata, setMetadataError } = useActions(seoActions);

  const metadata = useSelector(({ metadata }) => metadata.metadata);
  const isLoadingMetadata = useSelector(({ metadata }) => metadata.isLoading);
  const errorMetadata = useSelector(({ metadata }) => metadata.error);

  useEffect(() => {
    fetchMetadata();

    return () => {
      setMetadataError(null);
    };
  }, [fetchMetadata, setMetadataError]);

  return {
    metadata,
    isLoadingMetadata,
    errorMetadata,
  };
};
