import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { Button } from 'components';

import { IngredientAsyncAutocomplete, useLazyIngredientQuery, useUpdateIngredientMutation } from 'entities/ingredient';

import { FormCheckbox, FormTextField } from 'shared/ui';

import { buildFormDataToSubmit, buildDefaultValues, editIngredientSchema } from '../lib/schema';

const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

export const EditIngredientForm = (props) => {
  const { ingredient } = props;
  const { id, masterIngredient } = ingredient;

  const [updateIngredient] = useUpdateIngredientMutation();
  const [loadIngredient] = useLazyIngredientQuery();

  const loadDefaultValues = useCallback(async () => {
    if (!masterIngredient) {
      return buildDefaultValues(ingredient);
    }

    try {
      const loadedMasterIngredient = await loadIngredient(masterIngredient.id).unwrap();

      return buildDefaultValues(ingredient, loadedMasterIngredient);
    } catch (error) {
      return buildDefaultValues(ingredient);
    }
  }, [id, ingredient, loadIngredient, masterIngredient]);

  const methods = useForm({
    resolver: yupResolver(editIngredientSchema),
    defaultValues: loadDefaultValues,
  });
  const { handleSubmit, formState, getValues } = methods;
  const { isValid, isLoading } = formState;

  const onSubmit = async (formData) => {
    try {
      await updateIngredient({ id, ...buildFormDataToSubmit(formData) }).unwrap();

      toast.success(`Ingredient updated successfully`, {
        autoClose: false,
      });
    } catch (error) {
      toast.error(error.message, {
        autoClose: false,
      });
    }
  };

  return <FormProvider {...methods}>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={styles.form}>
        <FormTextField name="name" label="Name*" />
        <FormTextField name="description" label="Description" />

        {!isLoading &&
          <IngredientAsyncAutocomplete name="masterIngredient" label="Master Ingredient" placeholder="None"
                                       defaultValue={getValues().masterIngredient} />
        }

        <FormCheckbox name="triaged" label="Triaged" />

        <Box sx={styles.actions}>
          <Button type="submit" className="field-actions__btn" variant="contained" title="Save" disabled={!isValid}>
            Save
          </Button>
        </Box>
      </Box>
    </form>
  </FormProvider>;
};
