import React, { useState } from 'react';
import { SearchField } from 'components';

export const SearchForm = ({ onSearch }) => {
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setValue('');
    onSearch(value);
  };

  return (
    <SearchField
      className="toolbar__field-search"
      value={value}
      placeholder="Search"
      onChange={handleChange}
      onSubmit={handleSubmit}
    />
  );
};
