import { apiV1Routes, baseApi } from 'shared/api';

const ingredientApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    ingredient: builder.query({
      query: (ingredientId) => ({
        url: apiV1Routes.apiV1Ingredient(ingredientId),
      }),
      providesTags: ['ingredients'],
    }),

    ingredients: builder.query({
      query: ({ pageNumber = 1, pageSize = 20, nameLike = null }) => ({
        url: apiV1Routes.apiV1Ingredients(),
        params: { pageNumber, pageSize, nameLike: nameLike ?? undefined },
      }),
      providesTags: ['ingredients'],
    }),

    updateIngredient: builder.mutation({
      query: (ingredient) => ({
        url: apiV1Routes.apiV1Ingredients(),
        method: 'PUT',
        body: ingredient,
      }),
      invalidatesTags: ['ingredients'],
    }),

    createIngredient: builder.mutation({
      query: (ingredient) => ({
        url: apiV1Routes.apiV1Ingredients(),
        method: 'POST',
        body: ingredient,
      }),
      invalidatesTags: ['ingredients'],
    }),

    deleteIngredient: builder.mutation({
      query: (ingredientId) => ({
        url: apiV1Routes.apiV1Ingredient(ingredientId),
        method: 'DELETE',
      }),
      invalidatesTags: ['ingredients'],
    }),
  }),
});

export const {
  useLazyIngredientQuery,
  useIngredientsQuery,
  useUpdateIngredientMutation,
  useCreateIngredientMutation,
  useDeleteIngredientMutation,
} = ingredientApi;
