const fetchCategories =
  () =>
  async (dispatch, _, { apiV1 }) => {
    dispatch(setCategoriesLoading(true));
    await apiV1
      .get('/categories')
      .then((response) => {
        dispatch(setCategories(response.data));
      })
      .catch((error) => {
        dispatch(setCategoriesError(error));
      })
      .finally(() => {
        dispatch(setCategoriesLoading(false));
      });
  };

const setCategories = (categories) => ({
  type: 'SET_CATEGORIES',
  payload: categories,
});

const setCategoriesLoading = (isLoading) => ({
  type: 'SET_CATEGORIES_LOADING',
  payload: isLoading,
});

const setCategoriesError = (error) => ({
  type: 'SET_CATEGORIES_ERROR',
  payload: error,
});

export const categoriesActions = {
  fetchCategories,
  setCategories,
  setCategoriesLoading,
  setCategoriesError,
};
