const fetchReports =
  (pageNumber = 1, pageSize = 20) =>
  async (dispatch, _, { apiV1 }) => {
    dispatch(setReportsLoading(true));
    await apiV1
      .get('/reports', {
        params: {
          pageNumber,
          pageSize,
        },
      })
      .then((response) => {
        dispatch(setReports(response.data));
      })
      .catch((error) => {
        dispatch(setReportsError(error));
      })
      .finally(() => {
        dispatch(setReportsLoading(false));
      });
  };

const setReportChecked =
  (id) =>
  async (dispatch, _, { apiV1 }) => {
    await apiV1.put(`/report/${id}`).then(() => {
      dispatch(setReportCheckedSuccess(id));
    });
  };

const setReports = (reports) => ({
  type: 'SET_REPORTS',
  payload: reports,
});

const setReportsLoading = (isLoading) => ({
  type: 'SET_REPORTS_LOADING',
  payload: isLoading,
});

const setReportsError = (error) => ({
  type: 'SET_REPORTS_ERROR',
  payload: error,
});

const setReportCheckedSuccess = (id) => ({
  type: 'SET_REPORT_CHECKED_SUCCESS',
  payload: id,
});

export const reportsActions = {
  fetchReports,
  setReportChecked,
  setReports,
  setReportsLoading,
  setReportsError,
  setReportCheckedSuccess,
};
