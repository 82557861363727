import React from 'react';
import { toast } from 'react-toastify';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { userActions } from 'store/actions';
import { shelfSchema } from 'utils';
import { useActions } from 'hooks';
import { Button, FormTextField } from 'components';

export const ShelfForm = ({ productId, productName, userName, userId }) => {
  const { addToShelf } = useActions(userActions);

  const toastMsg = productName
    ? `Product "${productName}" has been successfully added to user shelf`
    : `Product has been successfully added to "${userName}" shelf`;
  const label = userId ? 'Product ID' : 'User ID';

  const shelfForm = useForm({
    resolver: yupResolver(shelfSchema),
    mode: 'onChange',
  });

  const onAddToShelf = async (data) => {
    try {
      await addToShelf({
        userId: Number(userId || data.id),
        productId: Number(productId || data.id),
      });
      toast.success(toastMsg, {
        autoClose: false,
      });
      shelfForm.reset();
    } catch (error) {
      toast.error(error.message, {
        autoClose: false,
      });
    }
  };

  return (
    <FormProvider {...shelfForm}>
      <form action="#" className="form shelf-form" onSubmit={shelfForm.handleSubmit(onAddToShelf)}>
        <fieldset className="form__fieldset shelf-form__fieldset">
          <legend className="form__legend shelf-form__legend">Add to shelf</legend>
          <FormTextField label={label} name="id" />
        </fieldset>
        <div className="field-actions">
          <Button type="submit" variant="contained" className="field-actions__btn" title="Add to user shelf">
            Add to user shelf
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
