import { Error, Loading } from 'components';

import { DeleteIngredientButton } from 'features/delete-ingredient';
import { EditIngredientForm } from 'features/edit-ingredient';

import { IngredientItem } from 'entities/ingredient';

export const IngredientsList = ({ ingredients, isLoading, error }) => {
  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <div className="ingredients-list">
      <div className="wrapper">
        {ingredients?.length === 0 ? (<p>Not found</p>) : (ingredients?.map((ingredient) => (
          <IngredientItem key={ingredient.id}
                          ingredient={ingredient}
                          headerSlot={
                            <DeleteIngredientButton ingredient={ingredient} />
                          }
          >
            <EditIngredientForm ingredient={ingredient} />
          </IngredientItem>)))
        }
      </div>
    </div>
  );
};
