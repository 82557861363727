const fetchBrand =
  (id) =>
  async (dispatch, _, { apiV1 }) => {
    dispatch(setBrandLoading(true));
    await apiV1
      .get(`/brand/${id}`)
      .then((response) => {
        dispatch(setBrand(response.data));
      })
      .catch((error) => {
        dispatch(setBrandError(error));
      })
      .finally(() => {
        dispatch(setBrandLoading(false));
      });
  };

const addBrand =
  (brand) =>
  async (dispatch, _, { apiV1 }) => {
    await apiV1.post('/brand', brand);
  };

const updateBrand =
  (brand) =>
  async (dispatch, _, { apiV1 }) => {
    await apiV1.put('/brand', brand);
  };

const setBrand = (brand) => ({
  type: 'SET_BRAND',
  payload: brand,
});

const setBrandLoading = (isLoading) => ({
  type: 'SET_BRAND_LOADING',
  payload: isLoading,
});

const setBrandError = (error) => ({
  type: 'SET_BRAND_ERROR',
  payload: error,
});

export const brandActions = {
  fetchBrand,
  addBrand,
  updateBrand,
  setBrand,
  setBrandLoading,
  setBrandError,
};
