import React from 'react';

export const Icon = ({ className, icon, width = '24', height = '24', ...attrs }) => {
  const classes = ['icon'];

  if (className) {
    classes.push(className);
  }

  return (
    <svg className={classes.join(' ')} focusable="false" aria-hidden="true" width={width} height={height} {...attrs}>
      <use href={`#icon-${icon}`} />
    </svg>
  );
};
