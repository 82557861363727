import axios from 'axios';

const createAPI = (dispatch, url) => {
  const api = axios.create({
    baseURL: url,
  });

  const onSuccess = (response) => response;

  const onFail = (error) => {
    if (error.response.status === 401) {
      throw error;
    }

    throw error;
  };

  api.interceptors.response.use(onSuccess, onFail);

  return api;
};

export default createAPI;
